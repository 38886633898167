.open-sans-600 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: bold;
  font-variation-settings:
    "wdth" 100;
}

.open-sans-700 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

body {
  font-family: "Open Sans", sans-serif !important;
  color: #FFFFFF;
  direction: rtl; /* Set the global direction to RTL */

}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centered-rectangle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.centered-rectangle {
  width: 400px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Equivalent to 'background.paper' in MUI */
  border-radius: 16px; /* Equivalent to 'borderRadius' in MUI */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Equivalent to 'boxShadow' in MUI */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-centered-rectangle {
  width: 1000px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Equivalent to 'background.paper' in MUI */
  border-radius: 16px; /* Equivalent to 'borderRadius' in MUI */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Equivalent to 'boxShadow' in MUI */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LoginButton {
  margin: 10px;
}

.background-container {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
  background-image: url("../public/tools-background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.header {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo img {
  height: 40px;
}

.header__nav {
  flex-grow: 1;
  margin-left: 20px;
}

.header__nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header__nav-item {
  margin-right: 20px;
}

.header__nav-link {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.header__nav-link:hover {
  color: #007BFF;
}

.header__actions {
  display: flex;
  align-items: center;
}

.header__action-link {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  margin-left: 20px;
}

.header__action-link:hover {
  color: #007BFF;
}

.gradient-button:hover {
    border-width: 4px;
    transition: opacity 0.3s;
    border-color:#007BFF;
}
